import React, { FunctionComponent, useEffect, useState } from "react";
import { IconButton, Stack, TableCell, TableRow, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
    RefundReservation,
    TransactionReservation,
    TransactionTicket,
} from "shared/models/Transaction";
import { FlatButton } from "shared/components/FlatButton";
import { DialogHooks } from "shared/components/DialogComponent";
import { TransactionReservationDetail } from "shared/models/Reservation";
import { CatalogIDCode } from "shared/models/Catalog";
import RefundReservationDialog, { RefundAmounts } from "./RefundReservationDialog";
import { CurrencyComponent } from "../../Reports/components/CurrencyComponent";

interface Props {
    refundReservation?: RefundReservation;
    reservation: TransactionReservation;
    onSet: (refund: RefundReservation) => void;
}

const RefundReservationRow: FunctionComponent<Props> = (props) => {
    const { reservation, refundReservation } = props;
    const [open, handleClickOpen, handleClose] = DialogHooks();

    const [refundAmounts, setRefundAmounts] = useState({
        tax: 0,
        serviceCharge: 0,
        amount: 0,
    });
    useEffect(() => {
        setRefundAmounts({ tax: 0, serviceCharge: 0, amount: 0 });
    }, [reservation, refundReservation]);

    const { unitPrice, tax, serviceCharge } = reservation;
    const total = unitPrice + tax + serviceCharge;
    const handleRefundAmount = (
        data: RefundAmounts,
        ticketEdits: TransactionReservationDetail[],
    ) => {
        setRefundAmounts(data);
        props.onSet({
            catalogId: refundReservation?.catalogId ?? CatalogIDCode.Reservation,
            reservationId: reservation.reservationID,
            refundAmount: data.amount,
            refundServiceCharge: data.serviceCharge,
            refundTax: data.tax,
            appliedCardCode: refundReservation?.appliedCardCode ?? "",
            ticketEdits,
            description: reservation?.description ?? "",
        });
        handleClose();
    };

    const ticketEditCount = (transactionTicket: TransactionTicket[]) => {
        let totalTicketCount: number = 0;
        transactionTicket.forEach((ticket: TransactionTicket) => {
            totalTicketCount = ticket.tickets + totalTicketCount;
        });
        return totalTicketCount;
    };

    const totalRefundAmount =
        Number(refundReservation?.refundAmount) +
        Number(refundReservation?.refundServiceCharge) +
        Number(refundReservation?.refundTax);
    return (
        <>
            <TableRow>
                <TableCell>
                    <Typography>Res # {reservation.reservationID}</Typography>
                    <Typography>{reservation.description}</Typography>
                    <Typography>
                        Tickets # {ticketEditCount(reservation.transactionReservationDetails || [])}
                    </Typography>
                </TableCell>
                <TableCell>
                    <CurrencyComponent value={unitPrice} />
                </TableCell>
                <TableCell>
                    <CurrencyComponent value={tax} />
                </TableCell>
                <TableCell>
                    <CurrencyComponent value={serviceCharge} />
                </TableCell>
                <TableCell>
                    <CurrencyComponent value={total} />
                </TableCell>
            </TableRow>
            {refundReservation ? (
                <TableRow sx={{ borderBottom: "1px solid red" }}>
                    <TableCell
                        sx={{
                            borderBottomColor: (theme) => theme.palette.grey["900"],
                        }}
                    >
                        {totalRefundAmount <= 0 ? (
                            <Typography
                                fontWeight={600}
                                sx={{ color: (theme) => theme.palette.grey[800] }}
                            >
                                Max refund is 0
                            </Typography>
                        ) : refundAmounts.amount !== 0 || refundAmounts.serviceCharge !== 0 ? (
                            <Stack alignItems={"center"} direction={"row"}>
                                <IconButton color={"primary"} onClick={handleClickOpen}>
                                    <EditOutlinedIcon />
                                </IconButton>
                                <Typography>Refund Amounts</Typography>
                            </Stack>
                        ) : (
                            <FlatButton color={"primary"} onClick={handleClickOpen}>
                                Set refund amounts
                            </FlatButton>
                        )}
                    </TableCell>
                    <TableCell
                        sx={{
                            borderBottomColor: (theme) => theme.palette.grey["900"],
                        }}
                    >
                        {refundAmounts.amount > 0 ? (
                            <CurrencyComponent value={-1 * refundAmounts.amount} />
                        ) : null}
                    </TableCell>
                    <TableCell
                        sx={{
                            borderBottomColor: (theme) => theme.palette.grey["900"],
                        }}
                    ></TableCell>
                    <TableCell
                        sx={{
                            borderBottomColor: (theme) => theme.palette.grey["900"],
                        }}
                    >
                        {refundAmounts.serviceCharge > 0 ? (
                            <CurrencyComponent value={-1 * refundAmounts.serviceCharge} />
                        ) : null}
                    </TableCell>
                    <TableCell
                        sx={{
                            borderBottomColor: (theme) => theme.palette.grey["900"],
                        }}
                    ></TableCell>
                </TableRow>
            ) : null}
            {refundReservation ? (
                <RefundReservationDialog
                    maxRefund={{
                        amount: refundReservation.refundAmount,
                        tax: refundReservation.refundTax,
                        serviceCharge: refundReservation.refundServiceCharge,
                    }}
                    taxRate={reservation.taxRate ?? 0}
                    onSet={handleRefundAmount}
                    onCancel={handleClose}
                    open={open}
                    ticketEdits={refundReservation?.ticketEdits || []}
                />
            ) : null}
        </>
    );
};

export default RefundReservationRow;
