import { z } from "zod";
import { InterfaceToZod } from "./UtilsTypes";
import {
    AddOnTransaction,
    RescheduleReason,
    ReservationDetails,
    ReservationListItem,
    ReservationListItemCatalog,
    ReservationListItemRate,
    ReservationListResource,
    ReservationTransaction,
    ReservationWaiver,
    TicketDetail,
    TicketPerson,
    TransactionReservationDetail,
} from "./Reservation";

import { RateQuestionGroupSchema } from "./Rates.schema";
import { CartItemSchema } from "./Cart.schema";
import { AssignedSeatsCategorySchema, SelectedSeatsSchema } from "./Seats.schema";
import { DateTimeISOValidation } from "../utils/ZodValidations";
import { IRentalInsurance } from "./RentalInsurance";

export const RescheduleReasonSchema = z.object<InterfaceToZod<RescheduleReason>>({
    cd: DateTimeISOValidation,
    reason: z.string(),
    originalDateTime: DateTimeISOValidation,
});
export const RescheduleReasonsSchema = z.array(RescheduleReasonSchema);

export const TicketPersonSchema = z.object<InterfaceToZod<TicketPerson>>({
    ticketPersonId: z.number(),
    signedWaiverId: z.number().nullable(),
    participantFirstName: z.string().nullable(),
    participantLastName: z.string().nullable(),
    participantPhone: z.string().nullable(),
    height: z.number().nullable(),
    weight: z.number().nullable(),
    checkedIn: DateTimeISOValidation.nullable(),
    checkedInBy: z.string().nullable(),
    answersJson: z.string().nullable(),
});
const AddOnTransactionSchema = z.object<InterfaceToZod<AddOnTransaction>>({
    transactionId: z.number(),
    transactionItemId: z.number(),
    catalogID: z.number(),
    itemCode: z.string(),
    description: z.string(),
    quantity: z.number(),
    maxReturn: z.number(),
    price: z.number(),
    wholesale: z.number(),
    tax: z.number(),
    enteredBy: z.string(),
    transactionDate: DateTimeISOValidation,
    cash: z.boolean(),
});
export const TicketDetailSchema = z.object<InterfaceToZod<TicketDetail>>({
    reservationDetailId: z.number(),
    rateId: z.number(),
    description: z.string(),
    rateCode: z.string(),
    rate: z.number(),
    durationInSeconds: z.number(),
    tickets: z.number(),
    questionGroupId: z.number().nullable(),
    ticketPersons: z.array(TicketPersonSchema).nullable(),
    assignedSeats: z.array(SelectedSeatsSchema).nullable(),
    flatRate: z.boolean(),
    ticketMultiplier: z.number(),
    ticketIncrement: z.number(),
    seatAssingmentCategoryId: z.number().nullable(),
    expired: z.boolean().optional(),
});

export const TransactionReservationDetailSchema = z.object<
    InterfaceToZod<TransactionReservationDetail>
>({
    rate: z.number().nullable().optional(),
    rateId: z.number().nullable().optional(),
    tickets: z.union([z.string(), z.number()]),
    description: z.string().nullable(),
    questionGroupId: z.number().optional().nullable(),
    expired: z.boolean().optional(),
});

export const ReservationTransactionSchema = z.object<InterfaceToZod<ReservationTransaction>>({
    transactionId: z.number(),
    transactionItemId: z.number(),
    unitPrice: z.number(),
    tax: z.number(),
    serviceCharge: z.number(),
    maxRefund: z.number(),
    refundReasonId: z.number().nullable(),
    enteredBy: z.string(),
    transactionDate: DateTimeISOValidation,
    cash: z.boolean(),
    canRefund: z.boolean(),
    transactionReservationDetails: z.array(TransactionReservationDetailSchema).nullable(),
    originalTransactionId: z.number(),
    dispute: z.boolean(),
    hasSavedPayment: z.boolean(),
});
export const ReservationWaiverSchema = z.object<InterfaceToZod<ReservationWaiver>>({
    signedWaiverId: z.number(),
    participantNames: z.string(),
    created: DateTimeISOValidation,
    namesList: z.array(z.string()),
    status: z.number().optional().nullable(),
});
export const ReservationListResourceSchema = z.object<InterfaceToZod<ReservationListResource>>({
    resourceItemId: z.number(),
    type: z.string(),
    name: z.string(),
    startDateTime: DateTimeISOValidation,
    endDateTime: DateTimeISOValidation,
});
export const ReservationListItemCatalogSchema = z.object<
    InterfaceToZod<ReservationListItemCatalog>
>({
    quantity: z.number(),
    description: z.string(),
    paid: z.number().nullable(),
});

const ReservationListItemRateSchema = z.object<InterfaceToZod<ReservationListItemRate>>({
    rateId: z.number(),
    tickets: z.number(),
    rateCode: z.string(),
    rate: z.number(),
    waiverCount: z.number().nullable(),
    checkedInCount: z.number().nullable(),
    weight: z.number().nullable(),
});

export const ReservationListItemSchema = z.object<InterfaceToZod<ReservationListItem>>({
    addOns: z.array(ReservationListItemCatalogSchema),
    billingNames: z.nullable(z.array(z.string())),
    checkedIn: DateTimeISOValidation.nullable(),
    checkedInBy: z.string().nullable(),
    checkedInByName: z.string().nullable(),
    comment: z.string().nullable(),
    couponCodes: z.array(z.string()).nullable(),
    email: z.string().nullable(),
    firstName: z.string().nullable(),
    hasSavedPayment: z.boolean(),
    lastName: z.string().nullable(),
    mismatchedBillingNames: z.array(z.string()),
    name: z.string().nullable(),
    notified: z.number(),
    paid: z.number(),
    partnerCompanyName: z.string().nullable(),
    phone: z.string().nullable(),
    pictures: z.number(),
    price: z.number(),
    rates: z.array(ReservationListItemRateSchema),
    rentalInsuranceCount: z.number(),
    rentalInsuranceStatus: z.number(),
    rescheduleLinkSent: z.string().nullable(),
    rescheduleReasons: z.nullable(RescheduleReasonsSchema),
    reservationId: z.number(),
    reservationWaivers: z.array(ReservationWaiverSchema).nullable(),
    resources: z.array(ReservationListResourceSchema),
    seatAssignments: z.array(AssignedSeatsCategorySchema),
    transactionCount: z.number(),
    waiverId: z.number(),
});
export const ReservationDetailsSchema = z.object<InterfaceToZod<ReservationDetails>>({
    addOnTransactions: z.array(AddOnTransactionSchema),
    answersJson: z.string().nullable(),
    address: z.string().nullable(),
    checkedIn: z.string().nullable(),
    checkedInBy: z.string().nullable(),
    comment: z.string().nullable(),
    companyId: z.number(),
    companyName: z.string(),
    catalogItems: z.array(CartItemSchema),
    delivery: z.boolean(),
    email: z.string().nullable(),
    eventDateId: z.number(),
    eventId: z.number(),
    eventDateTime: DateTimeISOValidation,
    eventName: z.string(),
    firstName: z.string().nullable(),
    isOnHold: z.boolean(),
    isWaiverSupported: z.boolean(),
    lastName: z.string().nullable(),
    lat: z.number().nullable(),
    lon: z.number().nullable(),
    notified: z.number(),
    paidAmount: z.number(),
    pictures: z.number(),
    phone: z.string().nullable(),
    price: z.number(),
    questionsJson: z.string().nullable(),
    rateQuestionGroups: z.array(RateQuestionGroupSchema).nullable(),
    rescheduleLinkSent: z.string().nullable(),
    rescheduleReasons: z.array(RescheduleReasonSchema).nullable(),
    reservationId: z.number(),
    reservationTransactions: z.array(ReservationTransactionSchema),
    reservationWaivers: z.array(ReservationWaiverSchema),
    seatAssignments: z.array(AssignedSeatsCategorySchema),
    seatAssignmentChartKey: z.string().nullable(),
    subtotal: z.number().optional(), //TODO: change subtotal to required
    tax: z.number().optional(), //TODO: change tax to required
    taxRate: z.number(),
    ticketRequiredFields: z.number(),
    tscStatus: z.number(),
    ticketEdits: z.array(TicketDetailSchema),
    thisIsDeposit: z.boolean(),
    userId: z.string().nullable(),
    waiverId: z.number(),
});

export const RentalInsuranceSchema = z.object<InterfaceToZod<IRentalInsurance>>({
    rentalInsuranceStatusId: z.number(),
    reservationId: z.number(),
    insuredName: z.string(),
    indexId: z.number(),
    isRequired: z.boolean(),
    insuranceGateway: z.number(),
    insuranceBookingId: z.string(),
    status: z.number(),
    cd: z.string(),
    ud: z.string(),
});
