import { z } from "zod";
import { InterfaceToZod } from "./UtilsTypes";
import {
    CashCategoryReportItem,
    CashCategorySummary,
    CommissionReportItem,
    DailyReservationReportEvent,
    DailyReservationReportEventDate,
    DailyReservationReportReservation,
    DeliveryPickup,
    DeliveryReportReport,
    PartnerReportItem,
    PartnerReportSummary,
    PayoutDetailReport,
    PayoutSummaryReport,
    ReservationByEmployeeReport,
    SalesByDateRange,
    SalesByDateRangeCatalogItem,
    SalesByDateRangeDetail,
    SalesByDateRangeSummary,
    SalesByEmployeeReport,
    SaleType,
    TicketTypeSummaryReport,
    TipReportItem,
    TipReportSummary,
    TransactionReport,
    TransactionReportAccount,
    TransactionReportDetail,
    TransactionReportSummary,
} from "./Report";
import {
    ReservationListItemCatalogSchema,
    ReservationWaiverSchema,
    TransactionReservationDetailSchema,
} from "./Reservation.schema";
import { PersonnelAssignedSchema } from "./Event.schema";

export const TransactionReportSummarySchema = z.object<InterfaceToZod<TransactionReportSummary>>({
    onlineNonTaxable: z.number(),
    onlineTaxable: z.number(),
    posNonTaxable: z.number(),
    posTaxable: z.number(),
    serviceCharge: z.number(),
    directTax: z.number(),
    ebmCommission: z.number(),
    ebmServiceCharge: z.number(),
    refunds: z.number(),
    refundsTaxable: z.number(),
    refundsTax: z.number(),
    wholesaleSales: z.number(),
    wholesaleTax: z.number(),
    retailValue: z.number(),
    boostCommission: z.number(),
    ticketCount: z.number(),
    boostGross: z.number(),
    boostEbmCommission: z.number(),
    cashNonTaxable: z.number(),
    cashTaxable: z.number(),
    cashTaxCollected: z.number(),
    directSales: z.number(),
    totalSales: z.number(),
    netSales: z.number(),
    cashTotalNet: z.number(),
    totalNormalTaxable: z.number(),
    totalTaxable: z.number(),
    totalTaxCollected: z.number(),
});

export const SaleTypeSchema = z.nativeEnum(SaleType);

export const TransactionReportDetailSchema = z.object<InterfaceToZod<TransactionReportDetail>>({
    description: z.string(),
    transactionId: z.number(),
    billingFirstName: z.string().nullable(),
    billingLastName: z.string().nullable(),
    transactionDate: z.string(),
    cardProcessorId: z.number(),
    cartCompanyId: z.number(),
    percentCommission: z.number(),
    boost: z.boolean(),
    onlineSale: z.boolean().nullable(),
    processorCompanyId: z.number(),
    itemCode: z.string(),
    companyId: z.number(),
    catalogId: z.number(),
    reservationId: z.number(),
    quantity: z.number(),
    unitPrice: z.number(),
    wholesale: z.number(),
    tax: z.number(),
    serviceCharge: z.number(),
    ebmServiceCharge: z.number(),
    financialAccount: z.string(),
    wholesaler: z.string(),
    category: z.string().nullable(),
    transactionCategoryId: z.number().nullable(),
    ourProcessingFee: z.number(),
    processorFee: z.number(),
    gatewayId: z.number(),
    itemTotal: z.number(),
    wholesaleTotal: z.number(),
    saleType: SaleTypeSchema,
});

export const TransactionReportAccountSchema = z.object<InterfaceToZod<TransactionReportAccount>>({
    financialAccount: z.string(),
    summary: TransactionReportSummarySchema,
    transactions: z.array(TransactionReportDetailSchema),
});

export const TransactionReportSchema = z.object<InterfaceToZod<TransactionReport>>({
    items: z.array(TransactionReportAccountSchema),
    eventAccounts: z.array(TransactionReportAccountSchema).optional(),
    totals: TransactionReportSummarySchema,
    transactions: z.number(),
    transactionFees: z.number(),
});

export const TicketTypeSummaryReportSchema = z.object<InterfaceToZod<TicketTypeSummaryReport>>({
    eventId: z.number(),
    name: z.string(),
    rateDescription: z.string(),
    tickets: z.number(),
    value: z.number(),
});

export interface SalesByEmployeeDetail {
    description: string;
    itemCode: string;
    reservationId: number;
    unitPrice: number;
    quantity: number;
    total: number;
    transactionDate: string;
}

export const SalesByEmployeeDetailSchema = z.object<InterfaceToZod<SalesByEmployeeDetail>>({
    description: z.string(),
    itemCode: z.string(),
    reservationId: z.number(),
    unitPrice: z.number(),
    quantity: z.number(),
    total: z.number(),
    transactionDate: z.string(),
});

export const SalesByEmployeeReportSchema = z.object<InterfaceToZod<SalesByEmployeeReport>>({
    firstName: z.string(),
    lastName: z.string(),
    totalSales: z.number(),
    details: z.array(SalesByEmployeeDetailSchema),
});

export const ReservationByEmployeeReportSchema = z.object<
    InterfaceToZod<ReservationByEmployeeReport>
>({
    userId: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    eventName: z.string(),
    reservationId: z.number(),
    reservationDate: z.string(),
    paidAmount: z.number(),
    tickets: z.number(),
});

export const PayoutSummaryReportSchema = z.object<InterfaceToZod<PayoutSummaryReport>>({
    payoutId: z.string(),
    initiated: z.string(),
    expected: z.string(),
    amount: z.number(),
    status: z.string(),
});

export const PayoutDetailReportSchema = z.object<InterfaceToZod<PayoutDetailReport>>({
    transactionId: z.number(),
    transactionDate: z.string(),
    name: z.string(),
    amount: z.number(),
    fees: z.number(),
    tax: z.number(),
    total: z.number(),
});

export const DeliveryPickupSchema = z.object<InterfaceToZod<DeliveryPickup>>({
    address: z.string().nullable(),
    lat: z.number().nullable(),
    lon: z.number().nullable(),
});

export const DeliveryReportReportSchema = z.object<InterfaceToZod<DeliveryReportReport>>({
    comment: z.string().nullable(),
    deliveryPickup: DeliveryPickupSchema,
    checkedIn: z.union([z.string(), z.date()]).nullable(),
    eventName: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    phone: z.string().nullable(),
    tickets: z.number(),
    rateDescription: z.string(),
    reservationId: z.number(),
    eventDateTime: z.string(),
    pickup: z.boolean(),
});

export const CommissionReportSchema = z.object<InterfaceToZod<CommissionReportItem>>({
    transactionId: z.number(),
    transactionDate: z.string(),
    reservationDate: z.string(),
    partnerCompanyName: z.string(),
    description: z.string(),
    name: z.string(),
    commission: z.number(),
});

export const CashCategoryReportItemSchema = z.object<InterfaceToZod<CashCategoryReportItem>>({
    transactionId: z.number(),
    transactionDate: z.string(),
    name: z.string(),
    category: z.string(),
    transactionCategoryId: z.number().nullable(),
    description: z.string(),
    tax: z.number(),
    price: z.number(),
    fee: z.number(),
});

export const CashCategorySummarySchema = z.object<InterfaceToZod<CashCategorySummary>>({
    category: z.string(),
    transactionCategoryId: z.number().nullable(),
    tax: z.number(),
    price: z.number(),
    fee: z.number(),
    rows: z.array(CashCategoryReportItemSchema),
});

export const CashCategorySummariesSchema = z.array(CashCategorySummarySchema);

export const TipReportItemSchema = z.object<InterfaceToZod<TipReportItem>>({
    eventId: z.number(),
    eventName: z.string(),
    eventDateId: z.number(),
    eventDateTime: z.string(),
    itemCode: z.string(),
    tipAmount: z.number(),
    tickets: z.array(TransactionReservationDetailSchema),
    assignedPersonnel: z.array(PersonnelAssignedSchema),
});

export const TipReportSummarySchema = z.object<InterfaceToZod<TipReportSummary>>({
    eventId: z.number(),
    eventName: z.string(),
    tipAmount: z.number(),
    tickets: z.array(TransactionReservationDetailSchema),
    items: z.array(TipReportItemSchema),
});

export const TipReportSummaries = z.array(TipReportSummarySchema);

export const SalesByDateRangeDetailSchema = z.object<InterfaceToZod<SalesByDateRangeDetail>>({
    cardProcessorId: z.number(),
    processor: z.string(),
    boost: z.boolean(),
    online: z.boolean(),
    inRange: z.boolean(),
    subtotal: z.number(),
    tax: z.number(),
    total: z.number(),
});

export const SalesByDateRangeCatalogItemSchema = z.object<
    InterfaceToZod<SalesByDateRangeCatalogItem>
>({
    catalogId: z.number(),
    itemCode: z.string(),
    description: z.string(),
    addOn: z.boolean(),
    online: z.boolean(),
    today: z.boolean(),
    cash: z.boolean(),
    quantity: z.number(),
    subtotal: z.number(),
    serviceCharge: z.number(),
    tax: z.number(),
    total: z.number(),
});

export const SalesByDateRangeSummarySchema = z.object<InterfaceToZod<SalesByDateRangeSummary>>({
    eventId: z.number(),
    eventName: z.string(),
    tickets: z.number(),
    details: z.array(SalesByDateRangeDetailSchema),
    cashSubtotal: z.number(),
    cashTax: z.number(),
    cashTotal: z.number(),
    inRangeCashSubtotal: z.number(),
    inRangeCashTax: z.number(),
    inRangeCashTotal: z.number(),
    inRangeCreditSubtotal: z.number(),
    inRangeCreditTax: z.number(),
    inRangeCreditTotal: z.number(),
    creditSubtotal: z.number(),
    creditTax: z.number(),
    creditTotal: z.number(),
    subtotal: z.number(),
    tax: z.number(),
    total: z.number(),
});

export const SalesByDateRangeSchema = z.object<InterfaceToZod<SalesByDateRange>>({
    reservations: z.array(SalesByDateRangeSummarySchema),
    catalogItems: z.array(SalesByDateRangeCatalogItemSchema),
});

export const PartnerReportItemSchema = z.object<InterfaceToZod<PartnerReportItem>>({
    companyId: z.number(),
    eventId: z.number(),
    eventDateId: z.number(),
    companyName: z.string(),
    eventName: z.string(),
    name: z.string().nullable(),
    reservationId: z.number(),
    eventDateTime: z.string(),
    createdDate: z.string(),
    tickets: z.array(TransactionReservationDetailSchema),
});

export const PartnerReportSummarySchema = z.object<InterfaceToZod<PartnerReportSummary>>({
    madeByPartners: z.array(PartnerReportItemSchema),
    madeForPartners: z.array(PartnerReportItemSchema),
});

export const DailyReservationReportReservationSchema = z.object<
    InterfaceToZod<DailyReservationReportReservation>
>({
    reservationId: z.number(),
    lastName: z.string().nullable(),
    firstName: z.string().nullable(),
    phone: z.string().nullable(),
    comment: z.string().nullable(),
    email: z.string().nullable(),
    tickets: z.array(TransactionReservationDetailSchema),
    merchandise: z.array(ReservationListItemCatalogSchema),
    checkedIn: z.string().nullable(),
    price: z.number(),
    paid: z.number(),
    reservationWaivers: z.array(ReservationWaiverSchema).nullable(),
});

export const DailyReservationReportEventDateSchema = z.object<
    InterfaceToZod<DailyReservationReportEventDate>
>({
    eventDateTime: z.string(),
    eventDateId: z.number(),
    publicNote: z.string().nullable(),
    privateNote: z.string().nullable(),
    reservations: z.array(DailyReservationReportReservationSchema),
    reservationCount: z.number(),
});

export const DailyReservationReportEventSchema = z.object<
    InterfaceToZod<DailyReservationReportEvent>
>({
    active: z.boolean(),
    eventName: z.string(),
    eventId: z.number(),
    eventDates: z.array(DailyReservationReportEventDateSchema),
    reservationCount: z.number(),
    colorClass: z.string(),
    eventDatesCount: z.number(),
    isWaiverSupported: z.boolean(),
});
