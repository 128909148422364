import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemText, Typography, Divider, useMediaQuery } from "@mui/material";
import { DateTimeUtils, FormatType } from "shared/utils/DateTimeUtils";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import UpdateIcon from "@mui/icons-material/Update";
import DialogComponent from "./DialogComponent";
import companyService from "shared/services/CompanyService";
import reservationService from "shared/services/ReservationService";
import { isEmpty } from "shared/utils/Common";
import { IconActionButton } from "./IconActionButton";
import { useIndexicTheme } from "theme/useIndexicTheme";

export interface UpdatedBy {
    updateDate: string;
    updateUser: string;
}
export interface IReservationHistory {
    ud: string;
    delta: string | null;
    action: string;
    changedBy: string;
}
export interface DeltaHistory {
    EventDateID: null;
    EventDateTime: string;
    Reservation: {
        FirstName: string;
        LastName: string;
        Phone: string;
        Email: string;
        Comment: string;
    };
    Details: {
        RateId: number;
        Description: string;
        Old: number;
        New: number;
    }[];
    EventID: number;
    EventName: string;
    CompanyID: number;
    CompanyName: string;
    FirstName: string;
    LastName: string;
    Phone: string;
    Email: string;
    Comment: string;
}
export interface CheckedInBy {
    checkedIn: string | null;
    checkedInBy: string;
    isCheckedIn: boolean;
    changes: IReservationHistory[] | null;
}
interface Props {
    renderButton: boolean;
    open?: boolean;
    history?: IReservationHistory[];
    eventUpdate?: UpdatedBy;
    CloseModal?: () => void;
}

const action = (actionCode: string) => {
    switch (actionCode) {
        case "A":
            return "Added";
        case "M":
            return "Moved";
        case "C":
            return "Changed";
        case "D":
            return "Deleted";
    }
    return "";
};

const WhoWhenComponent = (props: Props) => {
    const theme = useIndexicTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (props.open !== undefined) {
            setOpen(props.open);
        }
    }, [props.open]);
    const handleClose = () => {
        setOpen(false);
        if (props.CloseModal) {
            props.CloseModal();
        }
    };
    return (
        <React.Fragment>
            {props.renderButton ? (
                <IconActionButton
                    color="primary"
                    onClick={() => setOpen(true)}
                    icon={<UpdateIcon />}
                    label="Who/When"
                    showLabel={!isMobile}
                />
            ) : null}
            <DialogComponent
                DialogText="Last updates"
                open={open}
                handleClose={() => handleClose()}
            >
                {props.eventUpdate ? <EventHistory history={props.eventUpdate} /> : null}
            </DialogComponent>
        </React.Fragment>
    );
};
const EventHistory = (props: { history: UpdatedBy }) => {
    const [updatedBy, setUpdatedBy] = useState("");

    const getUserName = async (guid: string) => {
        const data = await companyService.getUser(guid);

        if (data) {
            setUpdatedBy(`${data.firstName} ${data.lastName}`);
        } else {
            setUpdatedBy("System Administrator");
        }
    };

    useEffect(() => {
        getUserName(props.history.updateUser).then();
    }, [props.history.updateUser]);

    return (
        <List
            style={{
                width: "100%",
                maxWidth: "36ch",
            }}
        >
            <React.Fragment>
                <ListItem alignItems="flex-start">
                    <ListItemText
                        primary={`Changed by ${updatedBy}`}
                        secondary={
                            <React.Fragment>
                                {" "}
                                <Typography
                                    component="span"
                                    variant="body2"
                                    style={{ display: "inline" }}
                                    color="textPrimary"
                                >
                                    On:
                                </Typography>
                                {` ${DateTimeUtils.newFormat(
                                    DateTimeUtils.eventLocalDate(props.history.updateDate),
                                    FormatType.fullDate,
                                )}`}
                            </React.Fragment>
                        }
                    />
                </ListItem>
            </React.Fragment>
        </List>
    );
};
export const ReservationHistoryItem = (props: { reservationId: number }) => {
    const [reservationHistory, setReservationHistory] = useState<CheckedInBy | null>(null);
    useEffect(() => {
        if (props.reservationId > 0) {
            getReservationHistory(props.reservationId).then();
        }
    }, [props.reservationId]);

    const getReservationHistory = async (reservationId: number) => {
        const data = await reservationService.getHistory(reservationId);
        setReservationHistory(data);
    };

    const changedBy = (name: string) => {
        return name !== " " ? `by ${name} ` : "";
    };

    const When = (actionCode: string, ud: string) => {
        return `${action(actionCode)} ${DateTimeUtils.formatLongDateTime(
            DateTimeUtils.UTCStringToLocalDate(ud),
        )}`;
    };

    const RenderAction = (action: string, delta: string) => {
        switch (action) {
            case "C":
                return <ChangedComponent delta={JSON.parse(delta)} />;
            case "M":
                return <MovedComponent delta={JSON.parse(delta)} />;
            case "D":
                return <DeletedComponent delta={JSON.parse(delta)} />;
            default:
                return null;
        }
    };
    return (
        reservationHistory && (
            <List
                style={{
                    width: "100%",
                }}
            >
                {reservationHistory.isCheckedIn && reservationHistory.checkedIn !== null ? (
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary={`Checked in by ${reservationHistory.checkedInBy}`}
                            secondary={`On ${DateTimeUtils.formatLongDateTime(
                                DateTimeUtils.UTCStringToLocalDate(reservationHistory.checkedIn),
                            )}`}
                        />
                    </ListItem>
                ) : null}
                {reservationHistory.changes && reservationHistory.changes.length > 0 ? (
                    reservationHistory.changes.map((item) => (
                        <React.Fragment key={item.ud}>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={When(item.action, item.ud)}
                                    secondary={
                                        <React.Fragment>
                                            {changedBy(item.changedBy)}
                                            {item.delta
                                                ? RenderAction(item.action, item.delta)
                                                : null}
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))
                ) : (
                    <Typography
                        component="span"
                        variant="body2"
                        style={{ display: "inline" }}
                        color="textPrimary"
                    >
                        There are no reservation history
                    </Typography>
                )}
            </List>
        )
    );
};
const ChangedComponent = (props: { delta: DeltaHistory }) => {
    return (
        <React.Fragment>
            {!isEmpty(props.delta.Reservation) ? (
                <ReservationDelta reservation={props.delta.Reservation} />
            ) : null}
            {!isEmpty(props.delta.Details) ? (
                <List
                    style={{
                        width: "100%",
                    }}
                >
                    {props.delta.Details.map((item) => (
                        <ListItem alignItems="flex-start" key={item.RateId}>
                            <ListItemText
                                primary={item.Description}
                                secondary={
                                    <div
                                        style={{
                                            display: "flex",
                                        }}
                                    >
                                        <span>{item.Old}</span>
                                        <ArrowRightAltIcon />
                                        <span>{item.New}</span>
                                    </div>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            ) : null}
        </React.Fragment>
    );
};
const MovedComponent = (props: { delta: DeltaHistory }) => {
    if (!props.delta.EventDateTime) {
        return null;
    }
    const eventDateTimeStr = `${DateTimeUtils.formatLongDateTime(
        DateTimeUtils.parse(props.delta.EventDateTime),
    )}`;
    return <ControlComponent label="From" value={eventDateTimeStr} />;
};
const DeletedComponent = (props: { delta: DeltaHistory }) => {
    return props.delta ? (
        <React.Fragment>
            <ControlComponent label="Event" value={props.delta.EventName} />
            <ControlComponent label="Event Time" value={props.delta.EventDateTime} />
            <ControlComponent label="First Name" value={props.delta.FirstName} />
            <ControlComponent label="Last Name" value={props.delta.LastName} />
            <ControlComponent label="Email" value={props.delta.Email} />
            <ControlComponent label="Phone" value={props.delta.Phone} />
            <ControlComponent label="Comment" value={props.delta.Comment} />
        </React.Fragment>
    ) : null;
};
const ReservationDelta = (props: { reservation: DeltaHistory["Reservation"] }) => {
    return (
        <React.Fragment>
            <ControlComponent label="First Name" value={props.reservation.FirstName} />
            <ControlComponent label="Last Name" value={props.reservation.LastName} />
            <ControlComponent label="Email" value={props.reservation.Email} />
            <ControlComponent label="Phone" value={props.reservation.Phone} />
            <ControlComponent label="Comment" value={props.reservation.Comment} />
        </React.Fragment>
    );
};
const ControlComponent = (props: { label: string; value: string }) => {
    const isValid = (value: unknown) => {
        return !(value === null || value === undefined);
    };
    return isValid(props.value) ? (
        <span style={{ marginBottom: 2 }}>
            {props.label}: <span>{props.value}</span>
        </span>
    ) : null;
};
export default WhoWhenComponent;
