import { FunctionComponent, useEffect, useState } from "react";
import "./UserAdmin.css";
import { IUser, UserRow } from "shared/models/User";
import {
    Button,
    Grid,
    InputAdornment,
    LinearProgress,
    Switch,
    SwitchProps,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import NoAccountsOutlinedIcon from "@mui/icons-material/NoAccountsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import userService from "shared/services/UserService";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import SystemAccess from "shared/components/SystemAccess/SystemAccessComponent";
import UserInvite from "./UserInvite";
import SlidePanel from "shared/components/SlidePanel";
import { DateTimeUtils, FormatType } from "shared/utils/DateTimeUtils";
import { useIndexicTheme } from "theme/useIndexicTheme";
import useNotification, { NotificationsType } from "shared/hooks/useNotification";
import companyService from "shared/services/CompanyService";
import DialogActiveInactiveUser from "./components/DialogActiveInactiveUser";

export type UserItemRow = UserRow & { id: string };

interface IOpenPermissions {
    opened: boolean;
    systemAccess: number;
    reportAccess: number;
    userName: string;
    userId: string;
}

const UserAdmin: FunctionComponent = () => {
    const theme = useIndexicTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { showNotification } = useNotification();
    const [rows, setRows] = useState<UserItemRow[]>([]);
    const [filteredRows, setFilteredRows] = useState<UserItemRow[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openAddUserToCompany, setOpenAddUserToCompany] = useState<boolean>(false);
    const [openPermissionsUser, setOpenPermissionsUser] = useState<IOpenPermissions>({
        opened: false,
        reportAccess: 0,
        systemAccess: 0,
        userName: "",
        userId: "",
    });

    const [openActiveInactiveUser, setOpenActiveInactiveUser] = useState<boolean>(false);
    const [userActiveInactiveSelected, setUserActiveInactiveSelected] = useState<{
        userId: string;
        active: boolean;
    }>({
        userId: "",
        active: false,
    });

    const [includeInactiveUsers, setIncludeInactiveUsers] = useState<boolean>(false);
    const [loadingUserRequest, setLoadingRequest] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");

    useEffect(() => {
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUsers = async () => {
        setIsLoading(true);

        try {
            const activeUsers = await userService.getAllUsers();
            const newArrUser: UserItemRow[] = activeUsers.map((obj: IUser) => {
                return {
                    id: obj.userId,
                    email: obj.email || "Not Available",
                    firstName: obj.firstName,
                    lastName: obj.lastName,
                    lastAccess: obj.lastAccess,
                    reportAccess: obj.reportAccess,
                    systemAccess: obj.systemAccess,
                    active: true,
                };
            });
            setRows(newArrUser);
            setFilteredRows(newArrUser);
        } catch {
            showNotification({
                message: "Error to display users",
                type: NotificationsType.error,
            });
        }
        setIsLoading(false);
    };

    const getInactiveUsers = async () => {
        setIsLoading(true);
        try {
            const inactiveUsers = await companyService.getCompanyInactiveUser();
            const newArrUser: UserItemRow[] = inactiveUsers.map((obj: IUser) => {
                return {
                    id: obj.userId,
                    email: obj.email || "Not Available",
                    firstName: obj.firstName,
                    lastName: obj.lastName,
                    lastAccess: obj.lastAccess,
                    reportAccess: obj.reportAccess,
                    systemAccess: obj.systemAccess,
                    active: false,
                };
            });

            const result = rows.concat(newArrUser);
            setRows(result);
            setFilteredRows(result);
        } catch {
            showNotification({
                message: "Error to display users",
                type: NotificationsType.error,
            });
        }
        setIsLoading(false);
    };

    const getAllUsers = async () => {
        setIsLoading(true);
        try {
            const activeUsers = await userService.getAllUsers();
            const inactiveUsers = await companyService.getCompanyInactiveUser();

            const newArrUser1: UserItemRow[] = activeUsers.map((obj: IUser) => {
                return {
                    id: obj.userId,
                    email: obj.email || "Not Available",
                    firstName: obj.firstName,
                    lastName: obj.lastName,
                    lastAccess: obj.lastAccess,
                    reportAccess: obj.reportAccess,
                    systemAccess: obj.systemAccess,
                    active: true,
                };
            });
            const newArrUser2: UserItemRow[] = inactiveUsers.map((obj: IUser) => {
                return {
                    id: obj.userId,
                    email: obj.email || "Not Available",
                    firstName: obj.firstName,
                    lastName: obj.lastName,
                    lastAccess: obj.lastAccess,
                    reportAccess: obj.reportAccess,
                    systemAccess: obj.systemAccess,
                    active: false,
                };
            });

            const result = newArrUser1.concat(newArrUser2);
            setRows(result);
            setFilteredRows(result);
        } catch {
            showNotification({
                message: "Error to display users",
                type: NotificationsType.error,
            });
        }
        setIsLoading(false);
    };

    const handleChangeToggle: SwitchProps["onChange"] = (e) => {
        setIncludeInactiveUsers(Boolean(e.target.checked));
        if (Boolean(e.target.checked)) {
            getInactiveUsers();
        } else {
            getUsers();
        }
    };

    const handleOpenPermissions = (
        systemAccess: number,
        reportAccess: number,
        userName: string,
        userId: string,
    ) => {
        setOpenPermissionsUser({
            opened: true,
            systemAccess,
            reportAccess,
            userName,
            userId,
        });
    };

    const handleActiveInactiveUser = async () => {
        setLoadingRequest(true);
        try {
            await userService.updateUserActiveStatus(userActiveInactiveSelected.userId);
            showNotification({
                type: NotificationsType.success,
                message: `User ${!userActiveInactiveSelected.active ? "Enabled" : "Disabled"}`,
            });

            if (includeInactiveUsers) {
                getAllUsers();
            } else {
                getUsers();
            }
            setUserActiveInactiveSelected({
                userId: "",
                active: false,
            });
            setOpenActiveInactiveUser(false);
        } catch {
            showNotification({
                type: NotificationsType.error,
                message: `Error to ${
                    !userActiveInactiveSelected.active ? "Enabled" : "Disabled"
                } User`,
            });
        }

        setLoadingRequest(false);
    };

    const handleChangeSearch = (event: any) => {
        setSearchText(event.target.value);

        if (event.target.value === "") {
            setFilteredRows(rows);
        } else {
            setFilteredRows(() =>
                rows.filter(
                    (value) =>
                        value.firstName.toLowerCase().includes(event.target.value.toLowerCase()) ||
                        value.lastName.toLowerCase().includes(event.target.value.toLowerCase()) ||
                        value.email.toLowerCase().includes(event.target.value.toLowerCase()),
                ),
            );
        }
    };

    const handleUpdateUser = (userUpdated: IUser) => {
        const updatedList = filteredRows.map((item) => {
            if (item.id === userUpdated.userId) {
                return {
                    ...item,
                    reportAccess: userUpdated.reportAccess,
                    systemAccess: userUpdated.systemAccess,
                };
            }
            return item;
        });
        setFilteredRows(updatedList);
    };

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "Commands",
            type: "singleSelect",
            minWidth: 150,
            flex: 1,
            renderCell: (params) => {
                return <>
                            <Button
                                onClick={() =>
                                    handleOpenPermissions(
                                        params.row.systemAccess,
                                        params.row.reportAccess,
                                        `${params.row.firstName} ${params.row.lastName}`,
                                        params.row.id,
                                    )
                                }
                                color="primary"
                                variant="contained"
                                size="small"
                                aria-label="Permissions."
                            >
                                <VpnKeyIcon />
                            </Button>
                            &nbsp;
                            <Button
                                onClick={() => {
                                    setUserActiveInactiveSelected({
                                        userId: params.row.id,
                                        active: params.row.active,
                                    });
                                    setOpenActiveInactiveUser(true);
                                }}
                                color="primary"
                                variant="contained"
                                size="small"
                                aria-label="Active."
                            >
                                {params.row.active ? (
                                    <AccountCircleOutlinedIcon />
                                ) : (
                                    <NoAccountsOutlinedIcon />
                                )}
                            </Button>
                </>;
            },
        },
        {
            field: "email",
            headerName: "Email",
            type: "string",
            flex: 2,
        },
        {
            field: "firstName",
            headerName: "First name",
            type: "string",
            flex: 2,
        },
        { field: "lastName", headerName: "Last name", type: "string", flex: 2, minWidth: 200 },
        {
            field: "lastAccess",
            headerName: "Last Activity",
            type: "string",
            flex: 2,
            valueFormatter: (params) => DateTimeUtils.newFormat(
                DateTimeUtils.parse(params.value), FormatType.shortWithDow
            )
        },
    ];

    return (
        <div className="user-admin">
            <div className="user-admin-header">
                <Typography variant={"reservationFullName"}>Manage Users</Typography>
            </div>

            {loadingUserRequest ? <LinearProgress /> : null}

            <div className="user-admin-container">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: ".5rem",
                        paddingBottom: ".5rem",
                    }}
                >
                    <TextField
                        size="small"
                        variant="outlined"
                        value={searchText}
                        placeholder="Type to search"
                        onChange={handleChangeSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    style={{
                                        display: searchText,
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setSearchText("");
                                        setFilteredRows(rows);
                                    }}
                                >
                                    <ClearIcon />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <div>
                        <Switch
                            checked={includeInactiveUsers}
                            onChange={handleChangeToggle}
                            data-cy="IncludeZeroTickets"
                        />
                        <Typography
                            style={{
                                color: "var(--grey-600)",
                                paddingRight: "1rem",
                                fontWeight: 500,
                            }}
                            component={"label"}
                        >
                            Show inactive users
                        </Typography>
                    </div>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => setOpenAddUserToCompany(true)}
                    >
                        {isMobile ? "Invite a user" : " Invite a user to your company"}
                    </Button>
                </div>
                <div style={{ minHeight: 650 }}>
                    <DataGridPro
                        autoHeight
                        rows={filteredRows}
                        columns={columns}
                        hideFooter={true}
                        loading={isLoading}
                        getRowClassName={(params) => params.row.active?"":"user-inactive"}
                    />
                </div>

                {/* Invite User to your company */}
                <SlidePanel
                    open={openAddUserToCompany}
                    onClose={() => setOpenAddUserToCompany(false)}
                    children={openAddUserToCompany && <UserInvite />}
                />

                {/* Permission user */}
                <SlidePanel
                    open={openPermissionsUser.opened}
                    onClose={() =>
                        setOpenPermissionsUser({
                            ...openPermissionsUser,
                            opened: false,
                            reportAccess: 0,
                            systemAccess: 0,
                            userName: "",
                            userId: "",
                        })
                    }
                    children={
                        openPermissionsUser.opened && (
                            <SystemAccess
                                userId={openPermissionsUser.userId}
                                systemAccess={openPermissionsUser.systemAccess}
                                reportAccess={openPermissionsUser.reportAccess}
                                userName={openPermissionsUser.userName}
                                handleUpdateUserPermissions={handleUpdateUser}
                            />
                        )
                    }
                />

                {/* Active / Inactive User */}
                <DialogActiveInactiveUser
                    openActiveInactiveUser={openActiveInactiveUser}
                    setOpenActiveInactiveUser={(state: boolean) => setOpenActiveInactiveUser(state)}
                    handleActiveInactiveUser={handleActiveInactiveUser}
                    userActiveInactiveSelected={userActiveInactiveSelected}
                    loadingUserRequest={loadingUserRequest}
                />
            </div>
        </div>
    );
};

export default UserAdmin;
