import React, { FunctionComponent, useState } from "react";
import { IRefundCatalogItem, RefundCatalogItem, TransactionItem } from "shared/models/Transaction";
import { IconButton, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { CurrencyComponent } from "../../Reports/components/CurrencyComponent";
import { FlatButton } from "shared/components/FlatButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { DialogHooks } from "shared/components/DialogComponent";
import { getCatalogNameFromId } from "shared/models/Catalog";
import RefundCatalogItemDialog, { RefundCatalogItemDialogProps } from "./RefundCatalogItemDialog";
import { useIndexicTheme } from "../../../theme/useIndexicTheme";

interface Props {
    catalogItem: TransactionItem;
    refundCatalogItem?: RefundCatalogItem;
    onSet: (data: IRefundCatalogItem) => void;
}

const RefundCatalogItemRow: FunctionComponent<Props> = (props) => {
    const theme = useIndexicTheme();
    const [open, handleClickOpen, handleClose] = DialogHooks();
    const { catalogItem, refundCatalogItem } = props;
    const { unitPrice, tax, serviceCharge, quantity } = catalogItem;
    const total = unitPrice * quantity + tax + serviceCharge;

    const [refundAmounts, setRefundAmounts] = useState({
        tax: 0,
        serviceCharge: 0,
        amount: 0,
    });

    const handleRefundAmount: RefundCatalogItemDialogProps["onSet"] = (data) => {
        props.onSet(data);
        setRefundAmounts({
            tax: 0,
            serviceCharge: data.refundServiceCharge,
            amount: data.transactions.reduce((t, curr) => t + curr.quantity * curr.unitPrice, 0),
        });
        handleClose();
    };
    const amount = quantity * unitPrice;
    return (
        <>
            <TableRow>
                <TableCell>
                    {catalogItem.catalogID <= 0 ? (
                        <Typography color={"primary"} fontStyle={"italic"} fontWeight={600}>
                            {getCatalogNameFromId(catalogItem.catalogID)}
                        </Typography>
                    ) : (
                        <Typography>Cat #{catalogItem.catalogID}</Typography>
                    )}
                    <Typography>{catalogItem.description}</Typography>
                </TableCell>
                <TableCell>
                    {amount !== 0 ? (
                        <span style={amount < 0 ? { color: theme.palette.error.main } : undefined}>
                            {quantity}x
                        </span>
                    ) : null}

                    <CurrencyComponent value={amount < 0 ? -unitPrice : unitPrice} />
                </TableCell>
                <TableCell>
                    <CurrencyComponent value={tax} />
                </TableCell>
                <TableCell>
                    <CurrencyComponent value={serviceCharge} />
                </TableCell>
                <TableCell>
                    <CurrencyComponent value={total} />
                </TableCell>
            </TableRow>
            {refundCatalogItem ? (
                <TableRow>
                    <TableCell
                        sx={{
                            borderBottomColor: (theme) => theme.palette.grey["900"],
                        }}
                    >
                        {refundCatalogItem.transactions.length <= 0 &&
                        refundCatalogItem.refundServiceCharge <= 0 ? (
                            <Typography
                                fontWeight={600}
                                sx={{ color: (theme) => theme.palette.warning.dark }}
                            >
                                All elements refunded
                            </Typography>
                        ) : refundAmounts.amount === 0 ? (
                            <FlatButton color={"primary"} onClick={handleClickOpen}>
                                Set refund amounts
                            </FlatButton>
                        ) : (
                            <Stack alignItems={"center"} direction={"row"}>
                                <IconButton color={"primary"} onClick={handleClickOpen}>
                                    <EditOutlinedIcon />
                                </IconButton>
                                <Typography>Refund Amounts</Typography>
                            </Stack>
                        )}
                    </TableCell>
                    <TableCell
                        sx={{
                            borderBottomColor: (theme) => theme.palette.grey["900"],
                        }}
                    >
                        {refundAmounts.amount > 0 ? (
                            <CurrencyComponent value={-1 * refundAmounts.amount} />
                        ) : null}
                    </TableCell>
                    <TableCell
                        sx={{
                            borderBottomColor: (theme) => theme.palette.grey["900"],
                        }}
                    >
                        {refundAmounts.tax > 0 ? (
                            <CurrencyComponent value={-1 * refundAmounts.tax} />
                        ) : null}
                    </TableCell>
                    <TableCell
                        sx={{
                            borderBottomColor: (theme) => theme.palette.grey["900"],
                        }}
                    >
                        {refundAmounts.serviceCharge > 0 ? (
                            <CurrencyComponent value={-1 * refundAmounts.serviceCharge} />
                        ) : null}
                    </TableCell>
                    <TableCell
                        sx={{
                            borderBottomColor: (theme) => theme.palette.grey["900"],
                        }}
                    ></TableCell>
                </TableRow>
            ) : null}
            {refundCatalogItem ? (
                <RefundCatalogItemDialog
                    maxRefund={refundCatalogItem}
                    taxRate={catalogItem.taxRate}
                    onSet={handleRefundAmount}
                    onCancel={handleClose}
                    open={open}
                />
            ) : null}
        </>
    );
};

export default RefundCatalogItemRow;
