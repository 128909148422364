import { HttpService, createUrl, createQueryParams, QueryParam } from "../http/http";
import {
    Company,
    DtoCompany,
    ICompanySearch,
    ICompanyUser,
    GiftCardInfo,
    IPublicNote,
    IRefundReason,
    SelectionItem,
    IReportingCategory,
} from "../models/Company";
import { ValidateSchema } from "../utils/ZodValidations";
import {
    CompanySchema,
    DtoCompanySchema,
    CompanySearchSchema,
    CompanyUsersSchema,
    GiftCardsInfoSchema,
    PublicNotesSchema,
    RefundReasonsSchema,
    SelectionItemsSchema,
} from "../models/Company.schema";
import { IUser } from "shared/models/User";
import { UserSchema, UsersSchema } from "shared/models/User.schema";
import { CatalogDetail } from "../models/Catalog";
import { CatalogDetailListSchema } from "../models/Catalog.schema";
import { Coupon } from "../models/Coupon";
import { CouponsSchema } from "../models/Coupon.schema";
import { Nullable } from "../models/Generals";
import { DateRange } from "../models/Report";

class PortalCompanyService extends HttpService {
    constructor() {
        super("/Portal/Company");
    }

    async getCompany() {
        const result = await this.get<Company>("");
        return ValidateSchema<Company>(result.data, CompanySchema);
    }
    async getCompanyUsers() {
        const result = await this.get<Array<ICompanyUser>>("/Users?onlyActive=true");
        return ValidateSchema<ICompanyUser[]>(result.data, CompanyUsersSchema);
    }

    async getCompanyInactiveUser() {
        const result = await this.get<Array<IUser>>("/Users?onlyActive=false");
        return ValidateSchema<Array<IUser>>(result.data, UsersSchema);
    }

    async getCompaniesSearch(search: string) {
        const result = await this.get<Array<ICompanySearch>>(`/Search/${search}`);
        return ValidateSchema<ICompanySearch[]>(result.data, CompanySearchSchema);
    }

    async getCompanyPublicNotes() {
        const uri = createUrl(["PublicNote"]);
        const result = await this.get<Array<IPublicNote>>(uri);
        return ValidateSchema<IPublicNote[]>(result.data, PublicNotesSchema);
    }

    async getRefundReasons() {
        const uri = createUrl(["RefundReason"]);
        const result = await this.get<Array<IRefundReason>>(uri);
        return ValidateSchema<Array<IRefundReason>>(result.data, RefundReasonsSchema);
    }

    async inviteUserToCompany(companyUser: ICompanyUser) {
        const uri = createUrl(["User", "Invite"]);
        const result = await this.post(uri, companyUser);
        return result;
    }
    async getCashSelectionTypes() {
        const uri = createUrl(["SelectionType", "cash"]);
        const result = await this.get<SelectionItem[]>(uri);
        return ValidateSchema<SelectionItem[]>(result.data, SelectionItemsSchema);
    }
    async getHowDidYouFindUsSelectionTypes() {
        const uri = createUrl(["SelectionType", "howheard"]);
        const result = await this.get<SelectionItem[]>(uri);
        return ValidateSchema<SelectionItem[]>(result.data, SelectionItemsSchema);
    }
    async getCompanyMerchandise() {
        const uri = createUrl(["Catalog"]);
        const result = await this.get<CatalogDetail[]>(uri);
        return ValidateSchema<CatalogDetail[]>(result.data, CatalogDetailListSchema);
    }
    async getCoupons(): Promise<Coupon[]> {
        const result = await this.get<Coupon[]>("Coupons");
        return ValidateSchema<Coupon[]>(result.data, CouponsSchema);
    }
    async getUser(userId: string) {
        const result = await this.get<IUser>(`User/${userId}`);
        return ValidateSchema<IUser>(result.data, UserSchema);
    }
    async searchGiftCards(
        searchTerm: Nullable<string>,
        includeZeroBalance: boolean,
        dateRange: Nullable<DateRange>,
    ): Promise<GiftCardInfo[]> {
        let uri = createUrl(["GiftCards"]);
        const params: QueryParam[] = [];
        if (!searchTerm && dateRange === null) {
            throw new Error("Search term or date range are required for gift cards search");
        }

        if (searchTerm) {
            params.push({
                key: "searchTerm",
                value: searchTerm,
            });
        } else if (dateRange) {
            params.push({ key: "startDate", value: dateRange.start });
            params.push({ key: "endDate", value: dateRange.end });
        }

        params.push({
            key: "includeZeroBalance",
            value: includeZeroBalance,
        });

        const searchStr = createQueryParams(params);
        if (searchStr !== "" && searchStr.length > 0) {
            uri += searchStr;
        }
        const result = await this.get<GiftCardInfo[]>(uri);
        return ValidateSchema(result.data, GiftCardsInfoSchema);
    }
    async sendGiftCardEmail(email: string, giftCardCode: string) {
        const uri = createUrl(["SendGiftCard", giftCardCode, email]);
        return this.post(uri, {});
    }
    async getCompanyNames(): Promise<string[]> {
        const result = await this.get<string[]>(`UrlFriendlyNames`);
        return result.data;
    }
    /**
     * Not necesary implementation of ZOD, just to know if is 200 okay it's enough to process UI
     */
    async acceptInviteCompanyUser(guid: string) {
        const result = await this.post(`User/Invite/Accept`, guid);
        return result;
    }

    async getReportingCategory() {
        const result = await this.get<IReportingCategory[]>("ReportingCategory");
        return result.data;
    }

    async retrieveCompany() {
        const uri = createUrl(["Retrieve"]);
        const result = await this.get<DtoCompany>(uri);
        return ValidateSchema<DtoCompany>(result.data, DtoCompanySchema);
    }
    async updateCompany(company: DtoCompany) {
        const result = await this.put(`Company`, company);
        return ValidateSchema(result.data, DtoCompanySchema);
    }
}

const companyService = new PortalCompanyService();
export default companyService;
