import { z } from "zod";
import { InterfaceToZod } from "./UtilsTypes";
import {
    AnswerSummary,
    CalendarDate,
    CancelEventResult,
    DocumentUpload,
    DocumentUploadType,
    EventDetail,
    Event,
    EventDateReservationSummary,
    EventDateTimeItem,
    EventGroupItem,
    EventTimeSummary,
    EventTimeTypes,
    EventsByPersonnel,
    GroupedPersonnelEventDates,
    IEventSettings,
    NotifyResult,
    PersonnelAssigned,
    PersonnelResponse,
    PortalEventSummaries,
    PortalEventSummary,
    RequiredPersonnel,
    TicketSummaries,
    EventSettingsResponse,
    DeleteEventDateRespose,
    TideStation,
} from "./Event";
import { DateTimeISOValidation } from "../utils/ZodValidations";
import { ReservationListItemSchema, ReservationListResourceSchema } from "./Reservation.schema";
import { RateEffectiveSchema, RateQuestionGroupSchema, RateSchema } from "./Rates.schema";
import { CatalogDetailSchema } from "./Catalog.schema";
import { ScheduleSchema } from "./Schedules.schema";
import { QuestionGroupName } from "./QuestionTypes";
import { EventRateEdit } from "./Rates";

export const PersonnelAssignedSchema = z.object<InterfaceToZod<PersonnelAssigned>>({
    userId: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    companyPersonnelTypeId: z.number(),
    typeName: z.string(),
});
export const EventTimeSummarySchema = z.object<InterfaceToZod<EventTimeSummary>>({
    companyId: z.number(),
    eventCode: z.string(),
    eventName: z.string(),
    colorClass: z.string(),
    eventDateId: z.number(),
    eventId: z.number(),
    partnerCompanyName: z.string().nullable(),
    eventDate: DateTimeISOValidation,
    startDateTime: DateTimeISOValidation,
    sellStatus: z.string(),
    ticketsSold: z.number(),
    privateNote: z.string().nullable(),
    publicNote: z.string().nullable(),
    maxTicketsOverride: z.number(),
    maxTickets: z.number(),
    threshold: z.number(),
    personnelIssue: z.boolean(),
    assignedPersonnel: z.array(PersonnelAssignedSchema).nullable(),
    resources: z.array(ReservationListResourceSchema).nullable(),
    noFollowUp: z.boolean(),
    noReminder: z.boolean(),
    rateAdjustmentPercent: z.number(),
    rateAdjustmentRoundingUnit: z.number(),
    reservationCount: z.number(),
    reportingCategoryId: z.number(),
    displayOrder: z.number(),
    ud: z.string(),
    uu: z.string(),
    unassignedPersonnelWarning: z.boolean().optional().nullable(),
});

export const EvenTimeTypesSchema = z.nativeEnum(EventTimeTypes);
export const PortalEventSummarySchema = z.object<InterfaceToZod<PortalEventSummary>>({
    eventId: z.number(),
    companyId: z.number(),
    companyName: z.string().nullable(),
    name: z.string(),
    active: z.boolean(),
    colorClass: z.string(),
    delivery: z.boolean(),
    eventCode: z.string(),
    maxTickets: z.number(),
    eventTimeType: EvenTimeTypesSchema,
    thresholdMode: z.number(),
    requireReservationUserId: z.boolean(),
    holdEventDateId: z.number(),
});

export const EventGroupItemSchema = z.object<InterfaceToZod<EventGroupItem>>({
    eventGroupId: z.number(),
    eventGroupName: z.string(),
    eventIds: z.array(z.number()),
    ud: z.string().nullable(),
    uu: z.string().nullable(),
});

export const PortalEventSummariesSchema = z.object<InterfaceToZod<PortalEventSummaries>>({
    events: z.array(PortalEventSummarySchema),
    eventGroups: z.array(EventGroupItemSchema),
});

export const EventSchema = z.object<InterfaceToZod<Event>>({
    name: z.string(),
    displayOrder: z.number(),
    companyId: z.number(),
    eventId: z.number(),
    companyName: z.string().nullable(),
    colorClass: z.string().nullable(),
    description: z.string().nullable(),
    durationInSeconds: z.number(),
    hideDuration: z.boolean().optional(),
    urlFriendlyName: z.string().nullable(),
    excludeFromReseller: z.boolean().optional(),
    lat: z.number().nullable(),
    lon: z.number().nullable(),
    minRate: z.number(),
    maxRate: z.number(),
    restrictions: z.string().nullable(),
    reservationInfo: z.string().nullable(),
    mapUrl: z.string().nullable(),
    maxTickets: z.number(),
    address: z.string().nullable(),
    latestSell: z.number(),
    latestChange: z.number().optional(),
    earliestSell: z.number(),
    eventTimeType: z.number(),
    ticketRequiredFields: z.number(),
    nextTime: z.string(),
    oneDay: z.boolean().optional(),
    singleTimeEventId: z.number(),
    rates: z.array(RateSchema),
    deposit: z.number(),
    depositOnly: z.boolean().optional(),
    delivery: z.boolean().optional(),
    questionsJson: z.string().nullable(),
    rateQuestionGroups: z.array(RateQuestionGroupSchema).nullable(),
    addOns: z.array(CatalogDetailSchema),
    seatAssignmentChartKey: z.string().optional().nullable(),
    seatAssignmentMode: z.number().optional().nullable(),
    seatingChartImg: z.string().optional().nullable(),
    isWaiverSupported: z.boolean().optional(),
    active: z.boolean().optional(),
    holdEventDateId: z.number(),
});

export const DocumentUploadTypeSchema = z.nativeEnum(DocumentUploadType);

export const DocumentUploadSchema = z.object<InterfaceToZod<DocumentUpload>>({
    type: DocumentUploadTypeSchema,
    description: z.string(),
});

export const EventDetailSchema = z.object<InterfaceToZod<EventDetail>>({
    eventId: z.number(),
    companyId: z.number(),
    name: z.string(),
    urlFriendlyName: z.string(),
    description: z.string().nullable(),
    restrictions: z.string().nullable(),
    reservationInfo: z.string().nullable(),
    durationInSeconds: z.number(),
    mapUrl: z.string().nullable(),
    maxTickets: z.number(),
    address: z.string().nullable(),
    timeZone: z.string(),
    lat: z.number().nullable(),
    lon: z.number().nullable(),
    active: z.boolean(),
    displayOrder: z.number(),
    // ud: z.string(),
    // uu: z.string(),
    excludeFromReseller: z.boolean(),
    taxable: z.boolean(),
    taxRate: z.number(),
    printedTickets: z.boolean(),
    latestSell: z.number(),
    latestChange: z.number().nullable().optional(),
    earliestSell: z.number(),
    eventTimeType: EvenTimeTypesSchema,
    financialAccount: z.string().nullable(),
    eventCode: z.string(),
    ticketRequiredFields: z.number(),
    deposit: z.number(),
    depositOnly: z.boolean(),
    delivery: z.boolean(),
    clickAgreementId: z.number(),
    thresholdMode: z.number(),
    hideDuration: z.boolean(),
    maxWeight: z.number(),
    returnScheduleId: z.number().nullable(),
    questionGroupId: z.number().nullable(),
    colorClass: z.string(),
    requireReservationUserId: z.boolean(),
    defaultRateAdjustmentRoundingUnit: z.number(),
    seatAssignmentMode: z.number(),
    seatAssignmentChartKey: z.string().nullable(),
    surchargeLabel: z.string().nullable(),
    surchargePercent: z.number(),
    surchargeTax: z.number(),
    holdEventDateId: z.number(),
    linkTipping: z.boolean(),
    documentUpload: z.boolean(),
    documentUploadList: z.array(DocumentUploadSchema).nullable(),
});

export const EventSettingsSchema = z.object<InterfaceToZod<IEventSettings>>({
    maxTicketsOverride: z.number().nullable(),
    publicNote: z.string().nullable(),
    privateNote: z.string().nullable(),
    sellStatus: z.string().nullable(),
    rateAdjustmentPercent: z.number().nullable(),
    rateAdjustmentRoundingUnit: z.number(),
    noFollowUp: z.boolean().nullable(),
    noReminder: z.boolean().nullable(),
    threshold: z.number().nullable(),
    reportingCategoryId: z.number().nullable(),
});

const AnswerSummarySchema = z.object<InterfaceToZod<AnswerSummary>>({
    questionType: z.number(),
    question: z.string().nullable(),
    answer: z.string().nullable(),
    total: z.number(),
});

const RequiredPersonnelSchema = z.object<InterfaceToZod<RequiredPersonnel>>({
    companyPersonnelTypeID: z.number(),
    typeName: z.string(),
    quantity: z.number(),
    ud: DateTimeISOValidation,
    uu: z.string().nullable(),
});

const TicketSummarySchema = z.object<InterfaceToZod<TicketSummaries>>({
    checkedInCount: z.number(),
    rate: z.string(),
    tickets: z.number(),
    waiverCount: z.number(),
    weight: z.number(),
});
export const EventDateReservationSummarySchema = z.object<
    InterfaceToZod<EventDateReservationSummary>
>({
    attachedWaiversCount: z.number(),
    ticketSummaries: z.array(TicketSummarySchema),
    reservations: z.array(ReservationListItemSchema),
    eventTimeSummary: EventTimeSummarySchema,
    answersSummary: z.array(AnswerSummarySchema),
    requiredPersonnel: z.array(RequiredPersonnelSchema).nullable(),
});

export const PersonnelResponseSchema = z.object<InterfaceToZod<PersonnelResponse>>({
    availablePersonnel: z.array(PersonnelAssignedSchema),
    requiredPersonnel: z.array(RequiredPersonnelSchema),
});
export const EventDateTimeItemSchema = z.object<InterfaceToZod<EventDateTimeItem>>({
    eventDateID: z.number(),
    eventDateTime: z.string(),
    sell_Status: z.string(),
    public_Note: z.string().nullable(),
    startTime: z.string(),
    endTime: z.string(),
    reason: z.string().nullable(),
    rateAdjustmentPercent: z.number(),
    rateAdjustmentRoundingUnit: z.number(),
    totalTicketAmount: z.number(),
});
export const EventDateTimeItemListSchema = z.array(EventDateTimeItemSchema);

export const CalendarDateSchema = z.object<InterfaceToZod<CalendarDate>>({
    day: z.string(),
    minTime: z.string(),
    timeCount: z.number(),
});
export const CalendarDateListSchema = z.array(CalendarDateSchema);

export const GroupedPersonnelEventDatesSchema = z.object<
    InterfaceToZod<GroupedPersonnelEventDates>
>({
    typeName: z.string(),
    companyPersonnelTypeId: z.number(),
    eventId: z.number(),
    eventName: z.string(),
    tickets: z.number(),
    eventDateTimeCount: z.number(),
});

export const EventsByPersonnelSchema = z.object<InterfaceToZod<EventsByPersonnel>>({
    firstName: z.string(),
    lastName: z.string(),
    userId: z.string(),
    events: z.array(GroupedPersonnelEventDatesSchema),
});

export const NotifyResultSchema = z.object<InterfaceToZod<NotifyResult>>({
    resultCode: z.number(),
    resultMessage: z.string(),
    validationError: z.string().nullable().optional(),
});
export const CancelEventResultSchema = z.object<InterfaceToZod<CancelEventResult>>({
    eventDateSettings: EventSettingsSchema,
    notifyResult: NotifyResultSchema,
});
export const QuestionGroupNameSchema = z.object<InterfaceToZod<QuestionGroupName>>({
    questionGroupId: z.number(),
    name: z.string(),
});
export const TideStationsSchema = z.object<InterfaceToZod<TideStation>>({
    name: z.string(),
    stationId: z.string(),
    distance: z.number(),
});
export const EventRateEditSchema = z.object<InterfaceToZod<EventRateEdit>>({
    eventId: z.number(),
    rateCode: z.string().nullable(),
    rateId: z.number(),
    description: z.string(),
    durationInSeconds: z.number(),
    maxTickets: z.number(),
    minReservation: z.number(),
    internal: z.boolean(),
    displayOrder: z.number(),
    questionGroupId: z.number().nullable(),
    rateEffectives: z.array(RateEffectiveSchema),
    ticketIncrement: z.number(),
    flatRate: z.boolean().nullable(),
    seatAssingmentCategoryId: z.number().nullable(),
    avgWeight: z.number(),
    maxReservation: z.number(),
    ticketMultiplier: z.number(),
    excludeFromThreshold: z.boolean(),
    excludeFromRateAdjustment: z.boolean(),
    ud: z.string(),
    uu: z.string(),
});
export const EventSettingsResponseSchema = z.object<InterfaceToZod<EventSettingsResponse>>({
    event: EventDetailSchema,
    schedules: z.array(ScheduleSchema),
    questionGroupNames: z.array(QuestionGroupNameSchema),
    tideStations: z.array(TideStationsSchema),
    rates: z.array(EventRateEditSchema),
});

export const DeleteEventDateResposeSchema = z.object<InterfaceToZod<DeleteEventDateRespose>>({
    resultCode: z.number(),
    resultMessage: z.string(),
});
