import { CartItem } from "./Cart";
import { Nullable } from "./Generals";
import { RateQuestionGroup } from "./Rates";
import { AssignedSeatsCategory, SelectedSeats } from "./Seats";
import { DateTimeString } from "./UtilsTypes";

export enum ReservationNotified {
    FollowUpEmailSent = 1 << 0,
    StopFollowUpEmail = 1 << 1,
    EmailSent = 1 << 2,
    EmailFailed = 1 << 3,
    EmailReminder = 1 << 4,
    SmsSent = 1 << 7,
    SmsFailed = 1 << 8,
    SmsConfirmed = 1 << 9,
    SmsRejected = 1 << 10,
    SmsReminder = 1 << 11,
}

export const ReservationNotifedName: { [key in ReservationNotified]: string } = {
    [ReservationNotified.FollowUpEmailSent]: "Follow-up email sent",
    [ReservationNotified.StopFollowUpEmail]: "Follow-up stopped",
    [ReservationNotified.EmailSent]: "Email Sent",
    [ReservationNotified.EmailFailed]: "Email failed",
    [ReservationNotified.EmailReminder]: "Reminder sent",
    [ReservationNotified.SmsSent]: "Sent confirmation",
    [ReservationNotified.SmsFailed]: "Fail to sent confirmation",
    [ReservationNotified.SmsConfirmed]: "Confirmation received",
    [ReservationNotified.SmsRejected]: "Confirmation rejected",
    [ReservationNotified.SmsReminder]: "Confirmation reminder",
};

type RescheduleReasons = RescheduleReason[];
export interface RescheduleReason {
    cd: DateTimeString;
    reason: string;
    originalDateTime: DateTimeString;
}

export interface TicketPerson {
    ticketPersonId: number;
    signedWaiverId: Nullable<number>;
    participantFirstName: Nullable<string>;
    participantLastName: Nullable<string>;
    participantPhone: Nullable<string>;
    height: Nullable<number>;
    weight: Nullable<number>;
    checkedIn: Nullable<DateTimeString>;
    checkedInBy: Nullable<string>;
    answersJson: Nullable<string>;
}

export interface AddOnTransaction {
    transactionId: number;
    transactionItemId: number;
    catalogID: number;
    itemCode: string;
    description: string;
    quantity: number;
    maxReturn: number;
    price: number;
    wholesale: number;
    tax: number;
    enteredBy: string;
    transactionDate: DateTimeString;
    cash: boolean;
}

export interface TicketDetail {
    reservationDetailId: number;
    rateId: number;
    description: string;
    rateCode: string;
    rate: number;
    durationInSeconds: number;
    tickets: number;
    questionGroupId?: number;
    ticketPersons: TicketPerson[];
    assignedSeats: Nullable<SelectedSeats[]>;
    flatRate: boolean;
    ticketMultiplier: number;
    ticketIncrement: number;
    seatAssingmentCategoryId: Nullable<number>;
    expired?: boolean; //this property is only used and set in the FrontEnd, this never comes from backend
}
export interface TransactionReservationDetail {
    rate: number;
    rateId: number;
    tickets: number;
    description: Nullable<string>;
    questionGroupId?: Nullable<number>;
    expired?: boolean; //this property is only used and set in the FrontEnd, this never comes from backend
}
export interface ReservationTransaction {
    transactionId: number;
    transactionItemId: number;
    unitPrice: number;
    tax: number;
    serviceCharge: number;
    maxRefund: number;
    refundReasonId: Nullable<number>;
    enteredBy: string;
    transactionDate: DateTimeString;
    cash: boolean;
    canRefund: boolean;
    transactionReservationDetails: Nullable<TransactionReservationDetail[]>;
    originalTransactionId: number;
    dispute: boolean;
    hasSavedPayment: boolean;
}
export interface ReservationWaiver {
    signedWaiverId: number;
    participantNames: string;
    created: DateTimeString;
    namesList: string[];
    status?: number | null;
}
export interface ReservationListResource {
    resourceItemId: number;
    type: string;
    name: string;
    startDateTime: DateTimeString;
    endDateTime: DateTimeString;
}
export interface ReservationListItemCatalog {
    quantity: number;
    description: string;
    paid: Nullable<number>;
}
export interface ReservationListItem {
    addOns: ReservationListItemCatalog[];
    billingNames: string[];
    checkedIn?: Nullable<DateTimeString>;
    checkedInBy: Nullable<string>;
    checkedInByName: Nullable<string>;
    comment: Nullable<string>;
    couponCodes: Nullable<string[]>;
    email: Nullable<string>;
    firstName: Nullable<string>;
    hasSavedPayment: boolean;
    lastName: string;
    mismatchedBillingNames: string[];
    name: string;
    notified: number;
    paid: number;
    partnerCompanyName: Nullable<string>;
    phone: Nullable<string>;
    pictures: number;
    price: number;
    rates: ReservationListItemRate[];
    rentalInsuranceCount: number;
    rentalInsuranceStatus: number;
    rescheduleLinkSent: Nullable<DateTimeString>;
    rescheduleReasons: Nullable<RescheduleReason[]>;
    reservationId: number;
    reservationWaivers: Nullable<ReservationWaiver[]>;
    resources: ReservationListResource[];
    seatAssignments: AssignedSeatsCategory[];
    transactionCount: number;
    waiverId: number;
}

export interface ReservationDetails {
    firstName: Nullable<string>;
    lastName: Nullable<string>;
    phone: Nullable<string>;
    email: Nullable<string>;
    addOnTransactions: Array<AddOnTransaction>;
    answersJson: Nullable<string>;
    address: Nullable<string>;
    checkedIn: Nullable<string>;
    checkedInBy: Nullable<string>;
    comment: Nullable<string>;
    companyId: number;
    companyName: string;
    catalogItems: CartItem[];
    delivery: boolean;
    eventDateId: number;
    eventId: number;
    eventDateTime: DateTimeString;
    eventName: string;
    isOnHold: boolean;
    isWaiverSupported: boolean;
    lat: Nullable<number>;
    lon: Nullable<number>;
    notified: number;
    paidAmount: number;
    pictures: number;
    price: number;
    questionsJson: string;
    rateQuestionGroups: Nullable<Array<RateQuestionGroup>>;
    rescheduleLinkSent: Nullable<string>;
    rescheduleReasons: Nullable<RescheduleReasons>;
    reservationId: number;
    reservationTransactions: Array<ReservationTransaction>;
    reservationWaivers: Array<ReservationWaiver>;
    seatAssignments: Array<AssignedSeatsCategory>;
    seatAssignmentChartKey: Nullable<string>;
    subtotal?: number; //TODO: change subtotal to required
    tax?: number; //TODO: change tax to required
    taxRate: number;
    ticketRequiredFields: number;
    tscStatus: number;
    ticketEdits: TicketDetail[];
    thisIsDeposit: boolean;
    userId: Nullable<string>;
    waiverId: number;
}

export interface ReservationListItemRate {
    rateId: number;
    tickets: number;
    rateCode: string;
    rate: number;
    waiverCount: Nullable<number>;
    checkedInCount: Nullable<number>;
    weight: Nullable<number>;
}

export interface IRefundDTO {
    refundReason: number;
    ticketAdjustments: Array<TransactionReservationDetail>;
}

export interface IPaymentGatewayResult {
    success: boolean;
    errors: Array<string>;
    transactionReference: Nullable<string>;
    authorization: Nullable<string>;
    cardLastDigits: Nullable<string>;
    brand: Nullable<string>;
    rechargeToken: Nullable<string>;
    amount: number;
}

export interface IRefundResult {
    transactionId: number;
    reservationId: number;
    paymentGatewayResult: IPaymentGatewayResult;
}

export interface ITicketPersonCheckedIns {
    ticketPersonId: number;
    rateId: number;
    checkedIn: number;
    checkedInBy: string;
}

export interface ICheckInCheckOut {
    eventDateId: number;
    reservationId: number;
    checkedInBy: string;
    checkedIn: string;
    ticketPersonCheckedIns: ITicketPersonCheckedIns;
}
