import { LoadingButton } from "@mui/lab";
import { Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import ReportAccessForm from "shared/components/SystemAccess/ReportAccessForm";
import SystemAccessForm from "shared/components/SystemAccess/SystemAccessForm";
import useNotification, { NotificationsType } from "shared/hooks/useNotification";
import { ICompanyUser } from "shared/models/Company";
import companyService from "shared/services/CompanyService";
import SendIcon from "@mui/icons-material/Send";
import { Validation } from "shared/utils/Validation";
import { ReportAccess, SystemAccess } from "shared/models/AccessRights";

const defaultInviteCompanyUser: ICompanyUser = {
    companyId: 0,
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    userId: "00000000-0000-0000-0000-000000000000",
    userPreferences: null,
    companyPreferences: null,
    systemAccess: 0,
    reportAccess: 0,
    active: false,
};
const UserInvite = () => {
    const { showNotification } = useNotification();
    const [companyUser, setCompanyUser] = useState<ICompanyUser>(defaultInviteCompanyUser);
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
    const [emailValid, setEmailValid] = useState<boolean>(false);
    const [resetForm, setResetForm] = useState<boolean>(false);

    const handleSendUserInvite = async () => {
        setLoadingRequest(true);
        try {
            await companyService.inviteUserToCompany(companyUser);
            showNotification({
                type: NotificationsType.success,
                message: "User invited.",
            });
            setCompanyUser(defaultInviteCompanyUser);
        } catch {
            showNotification({
                type: NotificationsType.error,
                message: "Cannot invite user.",
            });
        }
        setLoadingRequest(false);
    };

    const handleSystemAccess = (newSystemAccess: SystemAccess) => {
        setCompanyUser({ ...companyUser, systemAccess: newSystemAccess });
    };

    const handleReportAccess = (newReportAccess: ReportAccess) => {
        setCompanyUser({ ...companyUser, reportAccess: newReportAccess });
    };

    return (
        <div style={{ padding: ".5rem" }}>
            <Typography variant="h3">Invite user to your company</Typography>
            <Grid container spacing={2} paddingTop={".5rem"}>
                <Grid item xs={12} md={6}>
                    <Typography variant="inputLabel">First name</Typography>
                    <TextField
                        fullWidth
                        size="small"
                        name="firstName"
                        type="text"
                        variant="outlined"
                        placeholder="Type first name"
                        value={companyUser.firstName}
                        onChange={(e) =>
                            setCompanyUser({ ...companyUser, firstName: e.target.value })
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="inputLabel">Last name</Typography>
                    <TextField
                        fullWidth
                        size="small"
                        name="lastName"
                        type="text"
                        variant="outlined"
                        placeholder="Type last names"
                        value={companyUser.lastName}
                        onChange={(e) =>
                            setCompanyUser({ ...companyUser, lastName: e.target.value })
                        }
                    />
                </Grid>
            </Grid>

            <div style={{ paddingTop: ".5rem" }}>
                <Typography variant="inputLabel">Email</Typography>
                <TextField
                    size="small"
                    error={emailValid}
                    name="email"
                    type="text"
                    fullWidth
                    variant="outlined"
                    placeholder="Type email to send invitation"
                    value={companyUser.email}
                    onChange={(e) => {
                        setEmailValid(Validation.Email(e.target.value).length !== 0);
                        setCompanyUser({ ...companyUser, email: e.target.value });
                    }}
                    onFocus={(e) => e.target.select()}
                />
            </div>

            <div style={{ paddingTop: ".5rem" }}>
                <Typography variant="inputLabel">Permissions</Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <SystemAccessForm
                    systemAccess={companyUser.systemAccess}
                    onHandleReturnForm={handleSystemAccess}
                    resetForm={resetForm}
                />
                <ReportAccessForm
                    reportAccess={companyUser.reportAccess}
                    onHandleReturnForm={handleReportAccess}
                />
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Stack direction="row" spacing={2}>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                            setCompanyUser(defaultInviteCompanyUser);
                            setResetForm(!resetForm);
                        }}
                    >
                        CANCEL
                    </Button>
                    <LoadingButton
                        disabled={companyUser.email.length > 0 ? emailValid : true}
                        loading={loadingRequest}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleSendUserInvite}
                        startIcon={<SendIcon />}
                    >
                        Send invite
                    </LoadingButton>
                </Stack>
            </div>
        </div>
    );
};

export default UserInvite;
